import React, { useState, useRef, useEffect } from "react"

export default function Modal({ buttonText, children, className }) {
  const [modalOpen, setModalOpen] = useState(true)
  const modalRef = useRef()

  useEffect(() => {
    if (modalOpen) {
      modalRef.current?.showModal()
    } else {
      modalRef.current?.close()
    }
  }, [modalOpen])

  return (
    <div className="flex-column-nowrap">
      <dialog
        ref={modalRef}
        onCancel={() => setModalOpen(!modalOpen)}
        className={className}
      >
        <button
          className="pos-abs top-0 right-0 p-10"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <span className="color-light-corp color-hov-dark-corp fs-material material-icons va-sub">
            close
          </span>
        </button>
        {children}
        {buttonText ? (
          <button
            className="color-light-corp color-hov-dark-corp disp-block fs-xxl mt-30 mh-auto"
            onClick={() => setModalOpen(!modalOpen)}
          >
            {buttonText}
          </button>
        ) : undefined}
      </dialog>
    </div>
  )
}
