import React from "react"

import Modal from "./modal"

import { useTranslation } from "gatsby-plugin-react-i18next"

export default function ModalWrapper({ children }) {
  const { t } = useTranslation()

  return (
    <>
      {children}
      <Modal
        className="b-2-light-corp br-standard inset-0 pos-fix top-0 zi-5 p-30"
        buttonText={t("modal-button")}
      >
        <h1 className="heading-xl mb-30 ta-center">{t("modal-heading")}</h1>
        <p className="mb-15 fs-m">{t("modal-p1")}</p>
        <p className="mb-15 fs-m">{t("modal-p2")}</p>
        <p className="fs-m">{t("modal-p3")}</p>
        <a
          className="fw-bold color-light-corp color-hov-dark-corp fs-m"
          href="https://wartezone.tv"
          target="_blank"
          rel="noreferrer"
        >
          https://wartezone.tv/
        </a>
        <p className="mv-15 fs-m">{t("modal-p4")}</p>
        <p className="fs-m">
          {t("modal-p5-1")}

          <br />
          {t("modal-p5-2")}
        </p>
      </Modal>
    </>
  )
}
