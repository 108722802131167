// gatsby-ssr.js and gatsby-browser.js
const React = require("react")
const ModalWrapper = require("./src/components/modal-wrapper").default

exports.wrapPageElement = ({ element }) => {
  // Check if element.props.children exists and clone accordingly
  const childrenClone = element.props.children
    ? React.cloneElement(
        element.props.children,
        element.props.children.props,
        React.createElement(
          ModalWrapper,
          undefined,
          element.props.children.props.children,
        ),
      )
    : element.props.children // Just pass through if no children

  const newElement = React.cloneElement(
    element,
    element.props,
    childrenClone, // Use the possibly modified childrenClone
  )

  return newElement
}
